import React, { useState, useEffect, useContext } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
const Footer = dynamic(() => import('@/features/allHome/components/Footer/Footer'), { ssr: false });
const Header = dynamic(() => import('@/features/allHome/components/Header/Header'), { ssr: false });
const Home = dynamic(() => import('@/features/allHome/containers/Home/Home'), { ssr: false });
import initializeIconLibrary from '@/utils/fontAwesomeLibrary';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from '@/features/allHome/styles/pages.module.scss';
import WithStandardLayout from '@/layouts/withStandard.layout';
import Config from '@/features/allHome/json/config.json';
import Language from '@/utils/Language';
import useMixpanel from '@/hooks/useMixpanel';
import t from '@/features/allHome/utils/Language/AllHome-translations';

initializeIconLibrary();

const AllHomePage = (props) => {
  const [config, setConfig] = useState([]);
  const { track } = useMixpanel('Stay_Housed_Bay_Area');
  const [isError, setIsError] = useState(false);

  const languageChangeHandler = (event) => {
    const newState = Object.assign({}, config);
    newState.languageSettings.selected = event?.target?.value || 'en';
    Language.setCurrentLanguage(event?.target?.value);
    setConfig(newState);
  };

  useEffect(() => {
    track('Step in Stay_Housed_Bay_Area', { step: 'Viewed homepage' });
  }, []);

  useEffect(() => {
    setConfig(props.Config);
  }, [props.Config]);

  return (
    <div className={classes.container}>
      {isError && 'Error loading app config.  Please try again.'}
      {/* Skip Nav */}
      <a href="#site-menu" className={classes['sr-only']}>
        Skip to site menu
      </a>
      <a href="#content" className={classes['sr-only']}>
        Skip to main content
      </a>
      <a href="#ResourceSearchWizard" className={classes['sr-only']}>
        Skip to search
      </a>
      <Head>
        <title>{t(props.Config?.pages?.home?.title)}</title>
        <meta
          name="description"
          content="Resources for Bay Area residents to get the help they need to keep their housing, like rental assistance and legal services."
        />
      </Head>
      <Header config={props.Config} changeLanguage={languageChangeHandler} />
      <Home config={props.Config} changeLanguage={languageChangeHandler} />
      <Footer config={props.Config} changeLanguage={languageChangeHandler} />
    </div>
  );
};

export const getServerSideProps = async (ctx) => {
  return { props: { Config } };
};

// https://nextjs.org/docs/basic-features/layouts
// Layouts can be nested, we could use that functionality to make sure we include the Providers
// and data that every page requires
AllHomePage.getLayout = function getLayout(page) {
  return <WithStandardLayout>{page}</WithStandardLayout>;
};

export default AllHomePage;
